:root {
  --decor-zone-width: 240px;
  --decor-offset: -4px;
}

@media (max-width: 459.98px) {
  :root {
    --decor-zone-width: 160px;
    --decor-offset: -2px;
  }
}

$borderLineColor: #FDF6EF;

.feature {
  margin: 96px 0 0 0;
  padding: 48px 0 18px 0;
  display: inline-grid;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: clip;
  grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
  overflow-y: visible;

  &__mobile-border {
    display: none;
  }

  &--second {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    row-gap: 48px;
    margin-bottom: 64px;

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      background: linear-gradient(
                      90deg,
                      rgba($borderLineColor, 0) 0%,
                      rgba($borderLineColor, 1) 27%,
                      rgba($borderLineColor, 1) 78%,
                      rgba($borderLineColor, 0) 100%);
      width: 100%;
      bottom: calc(50% - 10px);
      left: 0;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background: linear-gradient(
                    90deg,
                    rgba($borderLineColor, 0) 0%,
                    rgba($borderLineColor, 1) 27%,
                    rgba($borderLineColor, 1) 78%,
                    rgba($borderLineColor, 0) 100%);
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &__decor {
    position: absolute;
    top: var(--decor-offset);
    left: 50%;
    transform: translateX(-50%);
    width: var(--decor-zone-width);
    pointer-events: none;
    z-index: 1;
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before, &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: calc(50% - var(--decor-zone-width) / 2 + 1px);
      top: 0;
    }

    &:before {
      left: 0;
      background: linear-gradient(
                      90deg,
                      rgba($borderLineColor, 0) 0%,
                      rgba($borderLineColor, 1) 27%,
                      rgba($borderLineColor, 1) 100%);
    }

    &:after {
      right: 0;
      background: linear-gradient(
                      90deg,
                      rgba($borderLineColor, 1) 0%,
                      rgba($borderLineColor, 1) 22%,
                      rgba($borderLineColor, 0) 100%);
    }
  }

  &__separator {
    display: none;
  }

  &__elem {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    flex-shrink: 0;
    position: relative;

    &--svg {
      flex-shrink: 0;
      width: 64px;
      height: 64px;
      position: relative;
      overflow: visible;
    }
  }

  &__line {
    background: linear-gradient(
                    180deg,
                    rgba($borderLineColor, 0) 0%,
                    rgba($borderLineColor, 1) 27%,
                    rgba($borderLineColor, 1) 78%,
                    rgba($borderLineColor, 0) 100%
    );
    align-self: stretch;
    flex-shrink: 0;
    width: 1px;
    position: relative;

    &--mobile {
      display: none;
    }

    &--short {
      background: linear-gradient(
                      180deg,
                      rgba($borderLineColor, 0) 0%,
                      rgba($borderLineColor, 0) 26%,
                      rgba($borderLineColor, 1) 53%,
                      rgba($borderLineColor, 1) 78%,
                      rgba($borderLineColor, 0) 100%
      );
    }
  }

  &__sub-elem {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    flex-shrink: 0;
    position: relative;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #fdf6ef;
    font-weight: 400;
    font-family: "Cinzel", serif;
    text-transform: uppercase;
    text-align: center;
  }

  &__container {
    padding: 8px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
  }

  &__value {
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    line-height: 38px;
    color: #fdf6ef;
    font-weight: 800;
    font-family: "Cinzel", serif;
  }
}

@media (max-width: 991.98px) {
  .feature {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 32px;
    padding-bottom: 0;
    padding-top: 30px;

    &:after {
      bottom: -16px;
    }

    &--second {
      margin-top: 32px;
      grid-template-columns: 1fr 1px 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      &:before {
        bottom: calc(33% - 10px);
      }
    }

    &__line {
      //display: none;

      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }


    &__separator {
      position: absolute;
      width: 100%;
      height: 2px;
      background: linear-gradient(
                      90deg,
                      rgba($borderLineColor, 0) 0%,
                      rgba($borderLineColor, 1) 27%,
                      rgba($borderLineColor, 1) 78%,
                      rgba($borderLineColor, 0) 100%);
      display: flex;
      top: calc(50% + 14px);
    }

    &__mobile-border {
      position: absolute;
      width: 100%;
      height: 2px;
      background: linear-gradient(
                      90deg,
                      rgba($borderLineColor, 0) 0%,
                      rgba($borderLineColor, 1) 27%,
                      rgba($borderLineColor, 1) 78%,
                      rgba($borderLineColor, 0) 100%);
      display: flex;
      top: calc(33% + 16px);
      left: 0;
    }

    &__sub-elem {
      display: grid;
      grid-template-rows: 1fr 1fr;
      max-width: 90%;
      gap: 8px;
      height: 100%;
    }

    &__elem {
      &--svg {
        width: 48px;
        height: 48px;
      }
    }

    &__text {
      font-size: 12px;
    }

    &__container {
      padding: 0 16px;
      justify-content: center;
    }

    &__value {
      font-size: 16px;
      line-height: 1.25;
    }
  }
}

@media (max-width: 359.98px) {
  .feature {
    &__sub-elem {
      max-width: 98%;
    }
  }
}