.container {
  max-width: 1770px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  &.small {
    max-width: 1200px;
  }
}

.header {
  display: flex;
  justify-content: center;
  padding: 23px 0;
}

.logo {
  z-index: 1;
  width: 330px;

  @media screen and (max-width: 992px) {
    width: 220px;
  }
}

.hero-image {
  position: relative;
  margin-bottom: 22.5%;

  &__dajana {
    position: absolute;
    bottom: -55%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 1;
    object-fit: contain;
    object-position: center;
    max-width: 60%;

    @media screen and (max-width: 991.98px) {
      bottom: -5%;
      max-width: 70%;

    }
  }
}

.dlhopisy-page {
  section {
    padding: 64px 0;

    @media screen and (max-width: 992px) {
      padding: 32px 0;
    }
  }

  &--purple {
    background-color: #753bbd;
  }

  &--net {
    position: relative;
    padding-top: 128px;

    @media screen and (max-width: 992px) {
      padding-top: 64px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background-image: url("./img/bg-grad-bottom.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url("./img/bg-bottom.png");
    }

  }

  &--first-grad {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background-image: linear-gradient(
                      0deg,
                      #000000 0%,
                      #210434 15%,
                      #410969 30%,
                      #512076 45%,
                      #612a88 50%,
                      #512076 65%,
                      #410969 69%,
                      #210434 74%,
                      #000000 82%,
                      #000000 100%
      );
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      z-index: -1;
      background-image: url("./img/bg-top.png"); // replace this with your image path
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &--image {
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      bottom: 35%;
      left: 0;
      right: 0;
      background-image: url("../src/img/globe.png");
      z-index: -1;
      aspect-ratio: 1920/929;
      background-repeat: no-repeat;

    }
  }

  &--no-padding {
    background-color: #fff;

    section {
      padding: 0;

      @media screen and (max-width: 992px) {
        padding: 32px 0;
      }
    }
  }

  &--solid-beige {
    overflow: hidden;
    background-color: #E1CECC;

    section {
      padding: 64px 0;

      @media screen and (max-width: 992px) {
        padding: 32px 0;
      }
    }
  }
}

.dlhopisy-hero {
  aspect-ratio: 1800/1100;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  max-width: 100%;

  @media screen and (max-width: 991.98px) {
    aspect-ratio: 1/1.39;
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
    max-width: 100%;
  }
}

.video-section {
  width: 100%;
  padding: 48px 0;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 28px;
  }

  &__half {
    display: flex;
    flex-direction: column;
    width: 50%;

    iframe, img {
      aspect-ratio: 1.75;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }

    img {
      object-fit: cover;
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding: 0;
  }

  &__title {
    font-size: 22px;
    line-height: 36px;
    color: #fdf6ef;
    font-weight: 800;
    font-family: "Cinzel", serif;
  }

  &__text {
    color: #fdf6ef;
    opacity: 0.65;
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 27px;
    font-family: "Circe";
  }
}

.column-features {
  display: grid;
  justify-content: space-between;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  @media screen and (max-width: 548px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
}

@media screen and (max-width: 992px) {
  .video-section {
    &__container {
      flex-direction: column;
      align-items: stretch;
      gap: 32px;
    }

    &__half {
      width: 100%;

      video {
        max-width: 100%;
        margin-top: 40px;
      }
    }

    &__texts {
      max-width: 100%;
    }

    &__title {
      max-width: 100%;
      font-size: 20px;

      br {
        display: none;
      }
    }

    &__text {
      max-width: 100%;
      margin-top: 16px;
      font-size: 14px;
    }
  }
}

.purple-text {
  color: #753bbd;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 38px;

  &__content {
    display: flex;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__bottom {
    margin-top: 64px;
  }

  &__title {
    color: #fdf6ef;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    font-family: "Cinzel", serif;

    @media screen and (max-width: 992px) {
      font-size: 20px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 16px;
    flex-shrink: 0;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__divider {
    background-color: #80878d;
    align-self: start;
    display: flex;
    width: 2px;
    height: 60px;
    flex-direction: column;
    margin: 15px 0 0 106px;

    @media screen and (max-width: 992px) {
      margin-left: 10px;
    }
  }

  &__images {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 77%;
    margin-left: 20px;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__description {
    color: #fdf6ef;

    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    width: 50%;

    @media screen and (max-width: 992px) {
      width: 100%;
      font-size: 18px;
    }
  }
}

.timeline-item {
  height: auto;
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 52px;
  color: #221e1e;
  line-height: 1.5;

  @media screen and (max-width: 992px) {
    gap: 26px;
  }

  &__caption {
    font-size: 22px;
    width: 58px;
    flex-shrink: 0;
    color: #f0dfcd;
    font-weight: bold;
    font-family: "Cinzel", serif;

    @media screen and (max-width: 992px) {
      font-size: 20px;
      width: 52px;
    }
  }

  &__description {
    font-family: 'Circe', sans-serif;
    font-size: 18px;
    font-weight: 400;

    @media screen and (max-width: 992px) {
      font-size: 16px;
    }

    @media screen and (max-width: 548px) {
      font-size: 14px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    color: #fdf6ef;
    font-family: "Cinzel", serif;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--final {
      font-size: 24px;
      font-weight: 800;
      line-height: 1.5;

      @media screen and (max-width: 992px) {
        font-size: 18px;
      }

      @media screen and (max-width: 380px) {
        font-size: 16px;
      }
    }
  }

  &__ellipse {
    height: 28px;
    width: 28px;
    position: relative;
    flex-shrink: 0;
    margin-top: 4px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      background-image: url("./img/blt.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }


    &--final {
      height: 28px;
      width: 28px;

      &:after {
        background-image: url("./img/blt-active.svg");
        width: 36px;
        height: 36px;
      }
    }
  }

  &__rectangle {
    background-color: #f0dfcd;
    height: calc(100% - 56px);
    width: 2px;
    position: absolute;
    left: 124px;
    top: calc(50% + 22px);
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: 992px) {
      left: 91px;
    }
  }

  &--final {
    color: #753bbd;
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  margin-left: auto;

  &__frame {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    visibility: hidden;

    &--type1 {
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 16px;
      position: relative;
    }

    &--type2 {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 16px;
      position: relative;
    }
  }

  &__image {
    position: relative;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 20px 60px 0 rgba(64, 0, 123, 0.25);
    width: 100%;

    &--type1 {
      max-height: 150px;
      max-width: 240px;
      aspect-ratio: 240/150;
      width: 100%;
      height: 100%;
    }

    &--type2 {
      max-height: 315px;
      max-width: 240px;
      aspect-ratio: 240/315;
      width: 100%;
      height: 100%;
    }

    &--type3 {
      max-height: 150px;
      max-width: 240px;
      aspect-ratio: 240/150;
      width: 100%;
      height: 100%;
    }
  }

  .button {
    margin-top: 64px;
  }
}

.photo-show {
  justify-content: space-between;
  display: flex;
  gap: 62px;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;
  height: 480px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    height: auto;
    gap: 28px;
  }

  &__info-column {
    display: flex;
    flex-direction: column;
    max-width: 575px;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 1;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  &__image-column {
    max-width: 475px;
    width: 100%;
    position: relative;
    flex-shrink: 1;
    flex-grow: 0;

    @media screen and (max-width: 992px) {
      width: 100%;
      max-width: 625px;
      margin: 0 auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 992px) {
      max-width: 100%;
    }
  }

  &__headline {
    font-size: 24px;
    line-height: 36px;
    color: #7a27b6;
    font-weight: 800;
    font-family: "Cinzel", serif;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      font-size: 20px;
    }
  }

  &__description {
    margin-top: 32px;
    font-size: 20px;
    line-height: 1.5;
    color: #311e33;
    font-family: "Circe";
    max-width: 575px;
    gap: 24px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      font-size: 16px;
      gap: 16px;
      margin-top: 16px;
    }
  }

  &__image {
    object-fit: contain;
    object-position: center;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    position: absolute;
    height: 120%;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 992px) {
      max-width: 100%;
      height: 100%;
      top: 0;
      transform: none;
      position: relative;
      margin-top: 0;
    }
  }
}

section.graph-show {
  padding-bottom: 0px;
}

.graph-show {
  display: flex;
  flex-direction: column-reverse;

  &__title {
    font-family: "Cinzel";
    display: flex;
    justify-content: center;
    font-size: 24px;
    line-height: 36px;
    color: #FDF6EF;
    font-weight: 800;
    text-align: center;
  }

  &__image {
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
    margin: auto 0;
    max-height: 1000px;

    @media screen and (max-width: 992px) {
      margin-top: 0;
    }
  }
}

.table-show {
  padding: 124px 0 !important;

  @media screen and (max-width: 992px) {
    padding: 64px 0 !important;
  }

  @media screen and (max-width: 768px) {
    padding: 32px 0 !important;
  }

  &__div {
    justify-content: space-between;
    display: flex;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  }

  &__column {
    &--full {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 575px;
      gap: 16px;

      @media screen and (max-width: 992px) {
        max-width: 100%;
      }
    }

    &--half {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 475px;
      justify-content: center;

      @media screen and (max-width: 992px) {
        max-width: 100%;
      }
    }
  }

  &__card {
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 24px 25px;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      padding: 16px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
  }

  &__title {
    color: #fdf6ef;
    letter-spacing: 0.54px;
    margin: auto 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;

    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }

  &__ltr-spacing {
    color: #753bbd;
    text-align: center;
    letter-spacing: 0.66px;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.2;

    @media screen and (max-width: 992px) {
      font-size: 18px;
    }
  }

  &__text {
    justify-content: center;
    color: #221e1e;
    text-align: right;
    flex-grow: 1;
    white-space: nowrap;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 992px) {
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      margin-top: 40px;
      gap: 16px;
    }
  }

  &__description {
    color: #000;
    letter-spacing: 0.54px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      font-size: 14px;
    }
  }

  &__text-title {
    font-weight: 600;
    line-height: 1.5;
    font-size: 24px;
    color: #000;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      font-size: 20px;
    }
  }

  &__button {
    color: #fff;
    text-align: center;
    letter-spacing: 1.32px;
    text-transform: uppercase;
    justify-content: center;
    background-color: #753bbd;
    padding: 18px 60px;
    font-weight: 600;
    line-height: 1;
    font-size: 22px;
    width: max-content;
    text-decoration: none;

    &:hover {
      background-color: darken(#753bbd, 15%);
    }

    @media screen and (max-width: 992px) {
      max-width: 100%;
      padding: 16px 24px;
      font-size: 18px;
    }
  }
}

.button {
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fdf6ef;
  font-weight: 400;
  justify-content: center;
  padding: 18px 60px;
  line-height: 1;
  width: max-content;
  text-decoration: none;
  border-radius: 9px;
  background-color: #19012b;
  border: 1px solid rgba(240, 223, 205, 0.75);
  position: relative;
  transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
  font-family: "Cinzel", serif;

  &:hover {
    background-color: lighten(#19012b, 10%);
  }

  &[disabled] {
    cursor: default;
    opacity: 0.4;
  }

  &--centered {
    margin: 0 auto;
  }

  &__circles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    span {
      &:before, &:after {
        width: 13px;
        height: 13px;
        border-radius: 8px;
        background-color: transparent;
        border: 2px solid #f0dfcd;
        position: absolute;
        content: "";
      }

      $offset: -1px;

      &:first-of-type {
        &:before {
          top: $offset;
          left: $offset;
        }

        &:after {
          bottom: $offset;
          right: $offset;
        }
      }

      &:nth-of-type(2) {
        &:before {
          top: $offset;
          right: $offset;
        }

        &:after {
          bottom: $offset;
          left: $offset;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    max-width: 100%;
    padding: 16px 24px;
    font-size: 18px;
  }

  &.investor-button {
    font-size: 18px;

    @media screen and (max-width: 992px) {
      font-size: 16px;
    }

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }

  &--small {
    font-size: 16px;
    padding: 16px 24px;

    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }
}

.purple-section {
  display: flex;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 24px;
  }
}

.reward-texts {
  display: flex;
  max-width: 575px;
  flex-direction: column;
  gap: 32px;

  &__description {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (max-width: 992px) {
      font-size: 14px;

      br {
        display: none;
      }
    }

    span {
      font-weight: 700;
      white-space: nowrap;
    }
  }

  &__title {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
    color: #e8c3ff;
    font-weight: bold;
    font-family: Cinzel, serif;

    @media screen and (max-width: 992px) {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 992px) {
    &__container {
      max-width: 100%;
    }
  }
}

.reward-video {
  object-fit: cover;
  object-position: top;
  width: 100%;
  overflow: visible;
  max-width: 420px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-25%);
  pointer-events: none;

  @media screen and (max-width: 992px) {
    position: relative;
    top: 0;
    transform: none;
    max-width: 280px;
    margin: 0 auto;
    object-position: center;
    object-fit: contain;
    overflow: hidden;
  }
}

.progress-bar {
  font-size: 22px;
  color: #fdf6ef;
  padding: 18px 60px;
  text-decoration: none;
  border-radius: 9px;
  background-color: rgba(240, 233, 205, 0.3);
  border: 1px solid rgba(240, 223, 205, 0.75);
  position: relative;
  transition: background-color 0.15s ease-in-out;
  font-family: "Cinzel", serif;

  &__inner {
    height: 22px;
    background-color: #19012b;
    border-radius: 9px;
    transition: width 0.15s ease-in-out;
  }

  @media screen and (max-width: 992px) {
    max-width: 100%;
    padding: 16px 24px;
    font-size: 18px;
  }
}