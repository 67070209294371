.image-carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin-top: 32px;
  aspect-ratio: 16/9;

  &__images {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }

  &__image {
    flex: 0 0 100%;
    height: 100%;
    object-fit: contain;
    max-height: 650px;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    z-index: 1;
    font-weight: 600;
    aspect-ratio: 1;

    &--left {
      left: 10px;
    }

    &--right {
      right: 10px;
    }
  }
}