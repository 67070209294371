.text-block {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;

  @media (max-width: 991.98px) {
    padding: 0;
  }

  &__heading {
    color: #311e33;
    font-size: 24px;
    line-height: 150%;
    font-weight: 700;
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    font-family: "Cinzel", serif;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    max-width: 644px;

    span {
      font-size: 16px;
      line-height: 125%;
      font-weight: 600;
      position: relative;
      width: 100%;
      color: #311e33;
    }

    &--highlight {
      color: #753bbd;
      text-decoration: underline;
      display: inline-block;
    }
  }
}