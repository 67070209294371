$black: #000000;
$purple: #7e00d8;
$white: #ffffff;
$light-gray: #80878d;
$granite-gray: #666666;
$border-color: rgba(128, 135, 141, 0.4);


.opt-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 580px;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 128px 0;
  min-height: 100vh;
    min-height: 100dvh;
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-image: url("./../../img/bg-grad-bottom.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url("./../../img/bg-bottom.png");
  }

  @media (max-width: 991.98px) {
    padding: 32px 0;
  }

  &__thanks {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    visibility: hidden;
    text-align: center;

    &--active {
      visibility: visible;
      pointer-events: all;
    }

    h2 {
      font-size: 32px !important;
      text-align: center !important;

      @media (max-width: 991.98px) {
        font-size: 24px !important;
      }
    }


    p {
      font-size: 22px !important;
      margin: 0;

      @media (max-width: 991.98px) {
        font-size: 16px !important;
      }
    }
  }

  &__content {
    display: contents;

    &--hidden {
      visibility: hidden;
      pointer-events: none;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    background-color: #18012a;
    border: 1px solid #f0dfcd;
    padding: 48px 72px;
    position: relative;
    max-width: 100%;

    @media (max-width: 991.98px) {
      padding: 48px 24px;
    }

    .circles {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;

      span {
        &:before, &:after {
          width: 13px;
          height: 13px;
          border-radius: 8px;
          background-color: transparent;
          border: 2px solid #f0dfcd;
          position: absolute;
          content: "";
        }

        $offset: -1px;

        &:first-of-type {
          &:before {
            top: $offset;
            left: $offset;
          }

          &:after {
            bottom: $offset;
            right: $offset;
          }
        }

        &:nth-of-type(2) {
          &:before {
            top: $offset;
            right: $offset;
          }

          &:after {
            bottom: $offset;
            left: $offset;
          }
        }
      }
    }
  }

  p {
    margin: 0 auto 16px;
    line-height: 1.25;
    text-align: center;
    color: #fcfcfd;
    font-family: "Circe", serif;
    font-size: 18px;
    max-width: 350px;
    width: 100%;

    @media (max-width: 991.98px) {
      font-size: 14px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 150%;
    font-weight: 600;
    margin: 0 auto 16px;
    font-family: "Cinzel", serif;
    color: #fcfcfd;
    text-align: center !important;
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    margin-bottom: 18px;
  }

  &__input-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    label {
      font-size: 13px;
      font-weight: 400;
      line-height: 130%;

      span {
        color: #7e00d8;
        padding-left: 5px;
      }
    }

    input {
      background: $white;
      border: 1px solid $border-color;
      padding: 14px 15px;
      font-size: 13px;
      font-weight: 400;
      line-height: 140%;
    }

    &--phone {
      flex-direction: row;

      select {
        width: 20px;
        height: 45px;
        border: 1px solid $border-color;
        margin-right: 10px;
      }
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 320px;

    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      color: #fcfcfd;

      span {
        color: #fcfcfd;
        text-decoration: underline;
      }
    }
  }

  &__submit {
    background: $purple;
    padding: 12px 40px;
    display: flex;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + .checkbox__custom {
      background: $black;

      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 1px;
        width: 4px;
        height: 8px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &__custom {
    position: relative;
    height: 18px;
    width: 18px;
    border: 1px solid $light-gray;
    background: transparent;
  }

  &__label {
    position: relative;
    max-width: 335.97px;
    width: 100%;
    color: #fcfcfd;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;

    &-detail {
      text-decoration: underline;
      color: #fcfcfd;

      &:hover {
        color: #7e00d8;
        transition: color 0.2s ease-in-out;
      }
    }

    small {
      color: #fcfcfd;
      font-size: 10px;
      text-decoration: underline;

      &:hover {
        color: #7e00d8;
        cursor: pointer;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}

.float-group {
  position: relative;

  label {
    position: absolute;
    left: 14px;
    font-size: 13px;
    color: $black;
    font-weight: 400;
    line-height: 1;
    pointer-events: none;
    transition: font-size 0.2s ease, color 0.2s ease, transform 0.2s ease, top 0.2s ease;
    padding: 0;
    background: transparent;
    top: 50%;
    transform: translateY(-50%);

    span {
      color: #7E00D8;
    }
  }

  input {
    margin-bottom: 0 !important;

    &:focus, &:not(:placeholder-shown) {
      //padding-bottom: 5px;

      + label {
        color: #bababa;
        font-size: 10px;
        top: 5px;
        transform: translateY(0);
      }
    }
  }

  select {
    margin-bottom: 0 !important;

    &:focus, &:not(:placeholder-shown) {
      padding-bottom: 5px;

      + label {
        color: #bababa;
        font-size: 10px;
        top: 5px;
        transform: translateY(0);
      }
    }
  }
}