.footer-bar {
  flex-shrink: 0;
  width: 100%;
  height: 90px;
  position: relative;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  &__info {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }
}