$borderLineColor: #FDF6EF;
$offset: -1px;

.column-feature {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 280px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #f0dfcd;
  border-radius: 9px;
  width: 100%;

  &__circles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;

    span {
      &:before, &:after {
        width: 13px;
        height: 13px;
        border-radius: 8px;
        background-color: transparent;
        border: 2px solid $borderLineColor;
        position: absolute;
        content: "";
      }

      &:first-of-type {
        &:before {
          top: $offset;
          left: $offset;
        }

        &:after {
          bottom: $offset;
          right: $offset;
        }
      }

      &:nth-of-type(2) {
        &:before {
          top: $offset;
          right: $offset;
        }

        &:after {
          bottom: $offset;
          left: $offset;
        }
      }
    }
  }

  &__block {
    background-color: #19012b;
    text-align: center;
    width: 100%;
    $ornament-width: 120px;
    $line-height: 3px;

    &--investment, &--interest, &--bonus, &--title {
      padding: 22px 0;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10%;
        right: 10%;
        height: $line-height;
        background-image: linear-gradient(90deg, rgba($borderLineColor, 0) 0%, rgba($borderLineColor, 1) 26%, rgba($borderLineColor, 1) 75%, rgba($borderLineColor, 1) 75%, rgba($borderLineColor, 0) 100%);
      }
    }

    &--investment {
      //Ornament in center of after and before
      position: relative;
      z-index: 0;

      &:before, &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(40% - $ornament-width / 2 + 5px);
        height: $line-height;
        left: 10%;
        background-image: linear-gradient(90deg, rgba($borderLineColor, 0) 0%, rgba($borderLineColor, 1) 100%);
        z-index: 0;
      }

      &:before {
        right: 10%;
        left: unset;
        transform: rotate(180deg);
      }

      .ornament {
        position: absolute;
        bottom: $line-height + 1px;
        width: $ornament-width;
        left: 50%;
        transform: translateX(-50%) translateY(100%);
        z-index: 2;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &--title {
      padding: 28px 0 16px;
    }

    &--bonus {
      height: 135px;
    }

    &--empty {
      @media (max-width: 992px) {
        display: none;
      }
    }

    &__title {
      font-family: "Cinzel", serif;
      font-weight: bold;
      padding-bottom: 12px;
    }

    &__title, &__major-detail {
      font-size: 20px;
      letter-spacing: 1px;
      color: #fdf6ef;
      font-weight: 400;
      margin-top: 0.3125rem;
    }

    &__sum, &__major-value {
      font-weight: bold;
      display: flex;
      justify-content: center;
      font-size: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #fdf6ef;
      font-family: "Cinzel", serif;
    }

    &__sum {
      &--small {
        font-size: 12px;
        letter-spacing: 1px;
        color: #fdf6ef;
        font-weight: 400;
        max-width: 80%;
        margin: 0 auto;
        line-height: 1.2;

        @media (max-width: 992px) {
          max-width: 75%;
        }
      }
    }

    &__button {
      padding: 20px 24px;
      display: flex;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      justify-content: center;
      align-items: center;
    }
  }

  &__readmore {
    display: inline-block;
    cursor: pointer;
    position: relative;

    &--icon {
      height: 14px;
      width: 14px;
      display: inline;
      overflow: hidden;
    }
  }
}

.column-feature-button {
  //visibility: hidden;
  color: #fdf6ef;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border: 1px solid $borderLineColor;
  border-radius: 9px;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Cinzel";

  &:hover {
    border-color: #fff;
    color: #7e00d8;

    .column-feature-button__icon {
      transform: translateX(4px);

      &__path {
        stroke: #7e00d8;
      }
    }
  }

  &__icon {
    display: inline;
    height: 14px;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
    width: 8px;
    transition: all 0.3s ease-in-out;
  }

  &__icon__path {
    transition: all 0.3s ease-in-out;
    stroke: #fdf6ef;
    fill: none;
  }
}